import React, { useEffect, useRef, useState } from 'react'
import { truncateByCharacters } from '../utils/FormatTitle'
import { formatDate } from '../utils/DateFormat'
import { Text } from '../../../Components'
import { ArrowLeftIcon, ChatListIcon, PlusBlue } from '../../../Themes/Images'
import { useChat } from '../../../Global/Chat'
import useStyles from '../AIAssistant,.styles'
import Modal1 from '../../../Components/Modal/Modal'
import { useInfiniteQuery } from 'react-query'

const ChatHistoryForMobile = ({ isOpen, onCancel }) => {
    const classes = useStyles()
    const [isDisabled, setDisabled] = useState(true)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const { chatsLeftBackend, handleChatOpen, createChatSession, chatHistory, emptyInput, setLoadingSession, onError } = useChat()
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError, refetch } = useInfiniteQuery(
        ['chatHistory'],
        async ({ pageParam = 0 }) => {
            const response = await chatHistory(pageParam)
            return response
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.length === 15 ? allPages.length : undefined
            },
            enabled: false,
        }
    )
    const createNewSession = () => createChatSession(emptyInput, setLoadingSession, onError)


    if (isError) {
        console.error('Error fetching chat history:', isError)
    }

    const modalContentRef = useRef()

    const handleClose = (e) => {
        // if (modalContentRef.current && modalContentRef.current.contains(e.target))
        //     return
        // setSuccess(false)
        // setError()
        // setDisabled(true)
        // setLoading(false)
        onCancel()

    }
    const chatHistoryModal = (
        <div className={classes.ModalWrapperChat}>
            <div className={classes.yourChatsMobile}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center' }} onClick={handleClose}>
                    <ArrowLeftIcon style={{ width: 16, height: 16 }} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                        <Text>Your Chats</Text>
                        <Text size='footnote'>{chatsLeftBackend} {chatsLeftBackend === 1 ? 'question' : 'questions'} left</Text>
                    </div>
                </div>
                <PlusBlue onClick={createNewSession} style={{ height: 24, width: 24, cursor: 'pointer' }} />
            </div>
            <div className={classes.chatsListMobile}>
                {data?.pages.flat().map((chat) => (
                    <div
                        key={chat._id}
                        onClick={() => handleChatOpen(chat._id)}
                        className={classes.containerOfChats}>
                        <div>
                            <ChatListIcon />
                        </div>
                        <div className={classes.currentChatMobile}>
                            <Text size='footnote'>{truncateByCharacters(chat?.title || 'No Title here')}</Text>
                            <Text size='smaller'>{formatDate(chat?.creation_date || 'No date available')}</Text>
                        </div>
                    </div>
                ))}
                <button
                    style={{ cursor: 'pointer', paddingBlock: 16 }}
                    className={classes.loadMoreButton}
                    onClick={(e) => {
                        e.stopPropagation()
                        fetchNextPage()

                    }}
                    disabled={isFetchingNextPage || !hasNextPage}
                >
                    {isFetchingNextPage
                        ? 'Loading more...'
                        : !hasNextPage
                            ? 'Nothing more to load'
                            : 'Load more chats'
                    }
                </button>
            </div>
        </div>
    )

    return (
        <Modal1 opened={isOpen} onClose={handleClose} type="noBlur" variant='explanation'>
            <div ref={modalContentRef}>
                {chatHistoryModal}
            </div>
        </Modal1>
    )
}

export default ChatHistoryForMobile
